export class ColorPalette {
  static bgColor = "#0E161B";
  static tabColor = "#150647";
  static accentColor = "#A84FBA";
  static bgGradient =
    "linear-gradient(to right, #36227C 0%, #0B091D 80%, #0B091D 100%)";
  static accentColor2 = "#3D2390";
  static accentColor2Bg = "#211652";
  static shadow1 = "0px 0px 30px rgba(11, 153, 224, 0.5)";
}

export class BaseInfo {
  static baseInfoDoc = {};
  static gamesNow = [];
  static gamesName = {};
  static chainTokensData = {};
  static gameTokenAddress = "0x5fd82ad8a72252a7a449aded99efec7dd8efa690";
  static ethChainID = "0x1";
  static arbChainID = "0xa4b1";
  static gamesMaxPlayers = {};
  static gamesPvpUrlType = {};
  static levelsThreshold = [];
  static p2eGames = {
    games_old: [{ url: "" }],
    games_active: [{ url: "" }],
    start_comp: "",
    show_before_min: 15,
  };
}

export class TaskDoc {
  static tasks = [];
  static points = [];
}

export class StatusInfo {
  static data;
}
export const versionGame = "1.3.64";
export const isTest = false;
